<template>
    <div class="home">
    <div v-wechat-title="this.title">
        <van-sticky :offset-top="0">
            <div class="page_top">
                <div class="date_choose">
                    <!-- <i class="icon iconfont iconarrow-left-filling" @click="subtractDate"></i> -->
                    <van-icon name="arrow-left"  @click="subtractDate"/>
                    <span class="date_info" @click="chooseCheckDay">{{date || '请选择'}}</span>
                    <van-icon name="arrow" @click="addDate" />
                    <!-- <i class="icon iconfont iconarrow-right-filling" @click="addDate"></i> -->
                </div>
                <van-search class="search_div" input-align="center" @search="reLoad()" background="#F8F7FC"  :clearable="false"  v-model="keyword" placeholder="请输入医生姓名查询" >
                    <template #right-icon>
                        <van-icon name="close"  @click="()=>keyword=''"  v-if="keyword"/>
                    </template>
                </van-search>
            </div>
            <van-tabs
                v-model="qrystatus" :offset-top="62"
                @change="changeTab" class="status_div"
                color="#E69F12"
                title-active-color="#E69F12"
                sticky
                swipeable>

            <van-tab title="门诊预约" name="0" >
            </van-tab>

            <van-tab  title="理疗预约" name="1" >
               
            </van-tab>

            <van-tab  title="体检预约" name="3"  >
                
            </van-tab>
        </van-tabs>
        </van-sticky>


        <van-calendar v-model="showCalendar" :default-date="defaultDate" :min-date="minDate" :show-confirm="false" @confirm="onConfirmCalendar" />

      
        <div class="treat_main_list" >
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            @load="reLoad"
                            :offset="30"
                            :immediate-check="false"
                            v-if="list.length>0"
                            finished-text="加载完成">
                        <div class="one"  v-for="item in list" :key="item.serviceid"  @click="detailCtrl(item.username,item.dispname,item.photo,item.intro)">
                            
                            <img class="photo" v-lazy="item.photo" alt="">
                            <div >
                                <div class="name">{{item.dispname}}</div>
                                <div class="surplusnum" v-if="item.surplusnum > 0">剩{{item.surplusnum}}个号</div>
                                <div class="full" v-if="item.surplusnum <= 0">已满诊，查看其他</div>
                                <div class="intro" v-if="item.intro">擅长：{{item.intro}}</div>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        当天无医生数据
                    </div>
                </div>
    </div>
</div>
</template>

<script>
    import { wxappointmentplaninfoqry } from '@/api/medical'
    import { mapGetters } from 'vuex'
    // import Vue from 'vue';
    import { Toast } from 'vant';
    import Vue from 'vue';
    import { Calendar,Lazyload } from 'vant';
    import errorImg from '@/assets/医生头像.png'
    import dayjs from 'dayjs';

    Vue.use(Calendar);
  
    // 注册时可以配置额外的选项
    Vue.use(Lazyload, {
        lazyComponent: true,
        loading: "https://yz.iyzzy.com/icon/loading.gif",
        error: errorImg
    });
    export default {
        computed: {
            ...mapGetters([
                'username','clinicid','isrefresh','isclose'
            ]),
        },
        data() {
            return {
                qrystatus: '0',
                list: [],
                finished:0,
                minDate: new Date(2020, 0, 1),
                defaultDate: new Date(),
                date:'',
                showCalendar:false,
                title:'预约管理',
                loading: false,
                keyword:''
            }
        },
        mounted () {
            this.$store.dispatch('changeIsClose', '1')
            this.$store.dispatch('changeNav', '0')
            this.$store.dispatch('hideOrShowNav', false)
            this.getCurDate()
            Toast({
                message: '加载中...',
                type: 'loading',
                duration: 0
            })
            this.reLoad()
        },
        activated() {
            this.$store.dispatch('changeNav', '0')
            this.$store.dispatch('hideOrShowNav', false)
            console.log(this.isrefresh, this.loading,'----isrefresh')
            if(this.isrefresh == '1' && !this.loading) {
                this.$store.dispatch('changeIsRefresh', '0')
                this.date = dayjs().format('YYYY-MM-DD')
                this.qrystatus = 0
                this.keyword = '';
                this.reLoad();
            }
            if(this.isrefresh == '2' && !this.loading) {
                this.$store.dispatch('changeIsRefresh', '0')
                this.date = dayjs().format('YYYY-MM-DD')
                this.keyword = '';
                this.reLoad();
            }
        },
        methods: {
            goBack() {
                window.WeixinJSBridge.call('closeWindow')
            },
            getCurDate() {
                const nowDate = new Date();
                this.formatDate(nowDate)
            },
            addDate() {
                var d = new Date(this.date);
                d.setDate(d.getDate() + 1);
                this.formatDate(d)
                this.reLoad()
            },
            subtractDate() {
                var d = new Date(this.date);
                d.setDate(d.getDate() - 1);
                this.formatDate(d)
                this.reLoad()
            },
            formatDate(d) {
                const curDate = {
                    year: d.getFullYear(),
                    month: d.getMonth() + 1,
                    date: d.getDate(),
                }
                const newmonth = curDate.month>=10?curDate.month:'0'+curDate.month
                const day = curDate.date>=10?curDate.date:'0'+curDate.date
                this.date = curDate.year + '-' + newmonth + '-' + day
                this.defaultDate = new Date(this.date);
            },
            chooseCheckDay(){
                this.showCalendar = true
            },
            onConfirmCalendar(date){
                this.showCalendar = false
                this.formatDate(date);
                Toast({
                    message: '加载中...',
                    type: 'loading',
                    duration: 0
                })
                this.reLoad()
            },
            changeRouter(url){
                if(url){
                    this.$router.push(url)
                }
        },

            changeTab(){
               
                Toast({
                    message: '加载中...',
                    type: 'loading',
                    duration: 0
                })
                this.reLoad()
            },
            reLoad(){
                // if(this.clinicid == null || this.clinicid == '' || this.clinicid == undefined
                //     || this.username == null || this.username == '' || this.username == undefined) {
                //     Toast.loading({
                //         duration: 0, // 持续展示 toast
                //         forbidClick: true,
                //         message: '未登录或未选择诊所，页面关闭',
                //     });
                //     let second = 1;
                //     const timer = setInterval(() => {
                //         second--;
                //         if (!second) {
                //             clearInterval(timer);
                //             // 手动清除 Toast
                //             Toast.clear();
                //             this.goBack()
                //         }
                //     }, 1000);
                //     return;

                // }
               
                this.loading = true
                this.finished = false
                let data = {
                    username: this.username,
                    qrydate: this.date,
                    keywords: this.keyword,
                    clinicid: this.clinicid,
                    qrytype:this.qrystatus
                }
             
                wxappointmentplaninfoqry(data).then(response => {
                     if(Toast){
                            Toast.clear()
                        }
                       
                        if(response.response_body && response.response_body.list){
                          
                                this.list = response.response_body.list
                        }
                        this.finished = true
                        this.loading = false
                    })
        


            },

            // 医生详情
            detailCtrl(username,dispname,photo,intro){
                this.$store.dispatch('changeIsRefresh', '0')
                sessionStorage.setItem('doctorname',username)
                sessionStorage.setItem('photo', photo)
                sessionStorage.setItem('dispname',dispname)
                sessionStorage.setItem('intro',intro)
                sessionStorage.setItem('qrytype',this.qrystatus)
                this.$router.push({
                    path: '/doctorMain',
                    query: {  
                        qrytype:this.qrystatus,
                        qrydate:this.date,
                    }
                })
            }
            
        },
    }
</script>

<style lang="scss" scoped>

.home{
  
  background: #fafafa;
  height: 100vh;
  overflow:auto;
}
    .van-info{
        top: 2px;
        right: -8px;
    }
    .van-cell{
        background-color: #F8F7FC;
        border-radius: 0.50667rem;
    }
    .bai {
        background-color: #ffffff;
    }
    .btnimg{
        width: 50px;
        height: 50px;
        position: fixed;
        right: 10px;
        bottom: 200px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .treat_main_list{
        margin-bottom:0px;
    }
    .treat_main_list .one{
        background: linear-gradient( 180deg, rgba(207,140,87,0.2) 0%, rgba(255,249,244,0) 20%);
    }
    .photo{
        min-width: 2.6rem;
        width: 2.6rem;
        height: 3rem;
        border-radius: 10px;
        margin-right: 10px;
    }
    .one{
        display: flex;
    }
    .name{
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }
    .surplusnum{
        color: #e69f12;
        background-color: #fbeec8;
        padding: 0.05333rem 0.33333rem;
        border-radius: 0.33333rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin: 5px 0px;
    }
    .intro{
       color: #999995;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #999995;
        white-space: pre-wrap;
        line-height: 0.55rem;
        max-width: 5.55rem;
    }
    .date_choose {
        border: 0.02667rem solid #dfdcdc;
        padding: 10px 2px;
        width: 150px;
    }
    .search_div{
        width: calc(100% - 160px);
    }
    :deep .van-field__control--center{
        text-align: left;
    }
    .full{
        background-color: #cf8c57;
        color: #fff;
        padding: 0.05333rem 0.33333rem;
        border-radius: 0.33333rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin: 5px 0px;
    }
   
</style>